/* styles.css */
.slide-image {
  width: 100%;
  height: 100%;
  object-fit: fill;
}

.slider {
  position: relative;
}

.slick-dots {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
}


.cat_slider_main {
  /* width: 100%; */
  /* height: 100px; */
  /* background-color: grey; */
}

.catSliderSection {
  width: 100%;
  height: auto;
  padding: 0px 0px 30px 0px;
}

.cat_slider_main .slick-slide{
  padding-right: 20px !important;
}

.cat_slider_main .item { 
  height: 100px;
  padding: 0.5rem;
  /* background-color: #ccc; */
}


.slick-arrow.slick-next,
.slick-arrow.slick-prev{
/* background-color: #428cff; */
height:25px;
width: 25px;
display: flex;
align-items: center;
justify-content: center;
border-radius: 50%;
opacity: 0.8;
height:30px;
width:30px;
}

.slick-arrow.slick-next:hover,
.slick-arrow.slick-prev:hover
{
    /* background-color: #2574f5; */
    opacity:1;
}


.slick-prev::before,.slick-next::before{
   display:none;
}

.arrows{
height:20px;
width:20px;
margin: 1px;

}