.slide-image {
    width: 100%;
    height: 260px;
    object-fit: fill;
}

.slider {
    position: relative;
}

.slick-dots {
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;
}

.slick-track {
    margin-left: 2rem;
    margin-right: 2rem;
}

.cat_slider_main {
    padding: 0.8rem;

}

.catSliderSection {
    width: 100%;
    height: auto;
    padding: 0px 0px 0px 0px;
    /* padding-right: 30px !important; */
}


/* .cat_slider_main .slick-slide {
    padding: auto;
    /* padding-right: 30px !important; */
/* } */
.cat_slider_main .item {
    height: 100px;
}

.slick-slide .slick-active .slick-current {
    width: 200px;
}

/* styles.css */
/* .catSliderSection {
    width: 100%;
    height: auto;
    padding: 0px 0px 30px 0px;
} */

.cat_slider_main .slick-slide {
    padding: 0 10px;
    /* Add padding between slides */
}



.slick-slide div {
    /* width: 100% !important; */
    /* width: 2; */
    /* Ensure full width usage */
}