/* .brands-carousel {
    margin-top: -50px;
    margin-bottom: -40px;
} */

.brandslider {
    min-height: 15vh;
    display: grid;
    place-items: center;
}

.brandslider {
    height: 150px;
    margin: auto;
    position: relative;
    width: 100%;
    display: grid;
    place-items: center;
    overflow: hidden;
}

.brandslide-track {
    display: flex;
    width: calc(150px * 58);
    animation: scroll 33s linear infinite;
}

.brandslide-track:hover {
    animation-play-state: paused;
}

@keyframes scroll {
    0% {
        transform: translateX(0)
    }

    100% {
        transform: translateX(calc(-150px * 20));
    }
}

.brandslide {
    height: 75px;
    width: 150px;
    display: flex;
    align-items: center;
    padding: 20px;
    perspective: 100px;
}

.brandslide img {
    width: 100%;
    transition: transform 1s;
}

img:hover {
    transform: translatez(15px)
}

.brandslider::before,
.brandslider::after {
    background: linear-gradient(to right, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
    content: '';
    height: 100%;
    position: absolute;
    width: 15;
    z-index: 2;
}

.brandslider::before {
    Left: 0;
    top: 0;
}

.brandslider::after {
    right: 0;
    top: 0;
    transform: rotatez(18deg);
}

@media only screen and (min-width: 360px) and (max-width: 479px) {
    .brands-carousel {
        margin-top: -50px;
        margin-bottom: 5px;
    }
}

@media only screen and (max-width: 768px) {
    .brandslider {
        width: 100%;
    }

    .bb {
        padding-bottom: 10px;
    }

    .brands-carousel {
        margin-bottom: -40px;
    }
}