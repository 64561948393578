.embla {
    --slide-spacing: 1rem;
    --slide-size: 100%;
    --slide-height: 25rem;
    /* padding: 1.6rem; */
  }
  .embla__viewport {
    overflow: hidden;
  }
  .embla__container {
    backface-visibility: hidden;
    display: flex;
    touch-action: pan-y;
    margin-left: calc(var(--slide-spacing) * -1);
    /* justify-content: center; */
  }
  
  .embla__slide {
    flex: 0 0 var(--slide-size);
    min-width: 0;
    padding-left: var(--slide-spacing);
    display: flex;
    justify-content: center; /* Center the content vertically and horizontally */
    align-items: center;
    position: relative;
  }
  
  .embla__slide__img {
    display: block;
    max-width: 100%;
    max-height: 100%; /* Ensure the image doesn't exceed the slide height */
    object-fit: cover;
  }
  .embla__slide__img_home {
    display: block;
    max-width: 80%;
    border-radius: 10px;
    min-height: 90%; /* Ensure the image doesn't exceed the slide height */
    object-fit: cover;
  }
  .embla__slide__img_home_mobile {
    display: block;
    max-width: 100%;
    border-radius: 10px;
    min-height: 90%; /* Ensure the image doesn't exceed the slide height */
    object-fit: cover;
  }
  .embla__slide__img_home2 {
    display: block;
    max-width: 50%;
    border-radius: 10px;
    max-height: 80%; /* Ensure the image doesn't exceed the slide height */
    object-fit: cover;
  }
  .embla__slide__number {
    width: 4.6rem;
    height: 4.6rem;
    z-index: 1;
    position: absolute;
    top: 0.6rem;
    right: 0.6rem;
    border-radius: 50%;
    background-color: rgba(var(--background-site-rgb-value), 0.85);
    line-height: 4.6rem;
    font-weight: 900;
    text-align: center;
    pointer-events: none;
  }
  .embla__slide__number > span {
    color: var(--brand-primary);
    background-image: linear-gradient(
      45deg,
      var(--brand-primary),
      var(--brand-secondary)
    );
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 1.6rem;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  .embla__button {
    -webkit-appearance: none;
    background-color: transparent;
    touch-action: manipulation;
    display: inline-flex;
    text-decoration: none;
    cursor: pointer;
    border: 0;
    padding: 0;
    margin: 0;
  }
  .embla__buttons {
    display: flex;
    align-items: center;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    /* left: 1.6rem; */
  }
  .embla__button {
    z-index: 1;
    /* color: 'red'; */
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    width: 3rem;
    height: 3rem;
  }
  .embla__button_mobile {
    width: 40px;
  }
  .embla__button:disabled {
    opacity: 0.3;
  }
  .embla__button__svg {
    width: 65%;
    height: 65%;
    color: #053a52;
  }
  .embla__button__svg_mobile {
    width: 30%;
    height: 30%;
    color: #053a52;
  }
  .embla__dot {
    -webkit-appearance: none;
    background-color: transparent;
    touch-action: manipulation;
    display: inline-flex;
    text-decoration: none;
    cursor: pointer;
    border: 0;
    padding: 0;
    margin: 0;
  }
  .embla__dots {
    z-index: 1;
    bottom: -40;
    position: absolute;
    background-color:'red';
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .embla__dots_mobile {
    z-index: 1;
    bottom: -40;
    position: absolute;
    background-color:'red';
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .embla__dot {
    width: 2.4rem;
    height: 2.4rem;
    display: flex;
    align-items: center;
    margin-right: 0.75rem;
    margin-left: 0.75rem;
  }
  .embla__dot:after {
    background: #e6f7ff;
    border-radius: 0.2rem;
    width: 80%;
    height: 0.2rem;
    content: '';
  }
  .embla__dot--selected:after {
    background: linear-gradient(
      45deg,
      #004666,
      #004666
    );
  }
  