/* * {
  font-family: Nunito, Monaco;
} */

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  /* font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace; */
}

/* .disable-scroll {
  overflow: hidden !important;
} */

&::-webkit-scrollbar {
  height: 0px;
  width: 0px;
}

&:hover::-webkit-scrollbar-thumb {
  background-color: #b4bcc3;
  border-radius: 8px;
}

&:hover::-webkit-scrollbar {
  height: 7px;
  width: 7px;
  background-color: var(--scrollbar-background-color);
}

.disable-scroll {
  overflow: hidden !important;
  overflow-y: auto;
  overflow-x: auto;
}

:root {
  --scrollbar-background-color: rgb(255, 255, 255);
}